import React from 'react';
import Layout from '../components/Layout';
import { ArenguForm } from "gatsby-plugin-arengu-forms";
import { graphql } from "gatsby";
import SEO from '../components/Seo';


const IndexPage = ({ data })=> (
  <Layout fullMenu>
    <SEO 
      title={data.contentYaml.title}
      description={data.contentYaml.description}
      pathname="/kontakt"
      keywords={data.contentYaml.keywords}
    />
    <section id="cta" className="wrapper style4">
     {/*Contact form*/}
     <div className="headline-contact-from">
       <h1>{data.contentYaml.headline}</h1>
       </div>
     <div id="main" className="contact-form">
       <ArenguForm
          id="160616362854181051"/>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
export const query = graphql`
  query {
    contentYaml(sitename: {eq: "kontakt"}) {
      title
      description
      keywords
      headline
    }
  }`