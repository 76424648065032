import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ title, description, pathname, keywords}) => {
  const { site } = useStaticQuery(query)

  const {
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      siteLanguage,
      defaultKeywords,
    },
  } = site

  const seo = {
    title: title ? title + " | " + defaultTitle : defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ''}`,
    keywords: keywords ? keywords : defaultKeywords
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
      </Helmet>
    </>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        siteLanguage
        defaultKeywords: keywords
      }
    }
  }
`
